export const projects  = [
    {
        id:1,
        title:'MERN stack App',
        description:'',
        info:'',
        demo:"https://tacho-maps-mern.vercel.app/",
        github:"https://github.com/pciruelos/tacho-maps-mern",
        fetured:true,
        img:'https://res.cloudinary.com/dhoebryld/image/upload/v1662470756/React%20Personal%20Web/Projects%20Img/1.png',
    },{
        id:2,
        title:'Redux Toolkit App Example',
        description:'',
        info:'',
        demo:"https://redux-toolkit-app-ciru.herokuapp.com/",
        github:"https://github.com/pciruelos/redux-toolkit",
        fetured:true,
        img:'https://res.cloudinary.com/dhoebryld/image/upload/v1662470759/React%20Personal%20Web/Projects%20Img/2.png',
    },{
        id:3,
        title:'MERN app - Img Uploader',
        description:'',
        info:'',
        demo:"https://ciru-mern-img-web.herokuapp.com/",
        github:"https://github.com/pciruelos/MERN-img",
        fetured:true,
        img:'https://res.cloudinary.com/dhoebryld/image/upload/v1662470766/React%20Personal%20Web/Projects%20Img/3.png',
    },{
        id:4,
        title:'React + Supabase',
        description:'',
        info:'',
        demo:"https://react-supabase-1-final.vercel.app",
        github:"https://github.com/pciruelos/react-supabase-1",
        fetured:true,
        img:'https://res.cloudinary.com/dhoebryld/image/upload/v1662470757/React%20Personal%20Web/Projects%20Img/4.png',
    },{
        id:5,
        title:'Personal Blog with NEXTjs',
        description:'',
        info:'',
        demo:"https://personal-next-blog-pi.vercel.app/",
        github:"https://github.com/pciruelos/personal-next-blog",
        fetured:true,
        img:'https://res.cloudinary.com/dhoebryld/image/upload/v1662470765/React%20Personal%20Web/Projects%20Img/5.png',
    },{
        id:6,
        title:'Rick and Morty API',
        description:'',
        info:'',
        demo:"https://react-rick-and-morty-api-pied.vercel.app/",
        github:"https://github.com/pciruelos/react-rick-and-morty-API",
        fetured:true,
        img:'https://res.cloudinary.com/dhoebryld/image/upload/v1662470771/React%20Personal%20Web/Projects%20Img/6.png',
    },{
        id:7,
        title:'React Old Website',
        description:'',
        info:'',
        demo:"https://ciru.website/",
        github:"https://github.com/pciruelos/cirureact",
        fetured:true,
        img:'https://res.cloudinary.com/dhoebryld/image/upload/v1662470764/React%20Personal%20Web/Projects%20Img/7.png',
    },{
        id:8,
        title:'Next Personal Old Web',
        description:'',
        info:'',
        demo:"https://ciruelos-next-web.herokuapp.com/",
        github:"https://github.com/pciruelos/next-personal-web",
        fetured:true,
        img:'https://res.cloudinary.com/dhoebryld/image/upload/v1662470761/React%20Personal%20Web/Projects%20Img/8.png',
    },
]

